@font-face {
    font-family: Gotham;
    src: url(../fonts/gotham/GothamBook.ttf);
    font-display: swap;
}

@font-face {
    font-family: Gotham;
    src: url(../fonts/gotham/Gotham-Bold.otf);
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: Gotham;
    src: url(../fonts/gotham/GothamMedium.ttf);
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: Gotham;
    src: url(../fonts/gotham/GothamBold.ttf);
    font-weight: 900;
    font-display: swap;
}

@font-face{
    font-family: works-sans;
    src: url(../fonts/Work_Sans/static/WorkSans-Regular.ttf);
    font-display: swap;
}

@font-face{
    font-family: works-sans;
    src: url(../fonts/Work_Sans/static/WorkSans-Bold.ttf);
    font-weight: bold;
    font-display: swap;
}

@font-face{
    font-family: works-sans;
    src: url(../fonts/Work_Sans/static/WorkSans-Medium.ttf);
    font-weight: 500;
    font-display: swap;
}

@font-face{
    font-family: works-sans;
    src: url(../fonts/Work_Sans/static/WorkSans-SemiBold.ttf);
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: works-sans;
    src: url(../fonts/Work_Sans/static/WorkSans-Black.ttf);
    font-weight: 900;
    font-display: swap;
}
@font-face{
    font-family: works-sans;
    src: url(../fonts/Work_Sans/static/WorkSans-Light.ttf);
    font-weight: 300;
    font-display: swap;
}
@font-face{
    font-family: Roboto;
    src: url(../fonts/Roboto/Roboto-Regular.ttf);
    font-display: swap;

}
@font-face{
    font-family: Roboto;
    src: url(../fonts/Roboto/Roboto-Bold.ttf);
    font-weight: bold;
    font-display: swap;
}
@font-face{
    font-family: Roboto;
    src: url(../fonts/Roboto/Roboto-Medium.ttf);
    font-weight: 500;
    font-display: swap;
}
@font-face{
    font-family: Roboto;
    src: url(../fonts/Roboto/Roboto-Light.ttf);
    font-weight: 300;
    font-display: swap;
}
@font-face{
    font-family: Roboto;
    src: url(../fonts/Roboto/Roboto-Black.ttf);
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: Lato;
    src: url(../fonts/Lato/Lato-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: Lato;
    src: url(../fonts/Lato/Lato-Bold.ttf);
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: Lato;
    src: url(../fonts/Lato/Lato-Light.ttf);
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: Lato;
    src: url(../fonts/Lato/Lato-Black.ttf);
    font-weight: 900;
    font-display: swap;
}



:root{
    --primary-zk-color:#004bda; /* #1C4C9A */
    --info-zk-color: #00bfc1;
    --primary-zkl-color: #f2f8fc;
    --secondary-zkl-color: #e8e9e8;
    --black-color: #000000;

}

*{
    padding: 0;
    margin: 0;
}

body{
    font-family: works-sans, sans-serif;
}

.text-work-sans{
    font-family: works-sans, sans-serif;
}

.text-roboto{
    font-family: Roboto, sans-serif;
}

.text-lato{
    font-family: Lato, sans-serif;
}

.text-zk-primary{
    color: var(--primary-zk-color);
}
.text-zk-black{
    color: var(--black-color);
}

.bg-zk-primary{
    background-color: var(--primary-zk-color);
}

.bg-zkl-primary{
    background-color: var(--primary-zkl-color);
}
.bg-zkl-secondary{
    background-color: var(--secondary-zkl-color);
}
.bg-secondary-zkl-color{
    background-color: var(--secondary-zkl-color);
}

.secondary-zkl-color{
    color: var(--secondary-zkl-color);
}

.w80px{
    width: 80px;
}

.border-zk-primary{
    border-top: 3px solid var(--primary-zk-color);
    border: none;
    height: 3px;
    opacity: 1;
    color: var(--primary-zk-color);
    background-color: var(--primary-zk-color);

}
.border-zk-white{
    border-top: 2px solid #fff;
    border: none;
    height: 2px;
    opacity: 1;
    color: #fff;
    background-color: #fff;

}

.fs-zk-56{
    font-size:56px;
}
.fs-zk-52{
    font-size:52px;
}

.fs-zk-48{
    font-size: 48px;
}

.fs-zk-40{
    font-size: 40px;
}
.fs-zk-35{
    font-size: 35px;
}

.fs-zk-32{
    font-size: 32px;
}

.fs-zk-30{
    font-size: 30px;
}

.fs-zk-28{
    font-size: 28px;
}
.fs-zk-26{
    font-size: 26px;
}
.fs-zk-24{
    font-size: 24px;
}
.fs-zk-22{
    font-size: 22px;
}

.fs-zk-20{
    font-size: 20px;
}

.fs-zk-18{
    font-size: 18px;
}

.fs-zk-16{
    font-size: 16px;
}
.fs-zk-15{
    font-size: 15px;
}

.fs-zk-14{
    font-size: 14px;
}

.text-worksans{
    font-family: works-sans, sans-serif;
}


.text-gotham{
    font-family: Lato, sans-serif;
}

.logo-img{
    height:60px;
}

.container-zk{
    max-width: 90%;
    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;
}

.container-zk2{
    max-width: 86%;
    padding-right: 7%;
    padding-left: 7%;
    margin-right: auto;
    margin-left: auto;
}

#website-nav .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

#website-nav .navbar-toggler{
    border: none;
    border-radius: 0;
    background-color: #fff;
}

#website-nav .nav-link{
    color: #000;
}
.navbar-offcanvas{
    /* background-color: var(--primary-zk-color);
    color: #fff; */
    padding: 0rem;
}

.take-control-bg{
    background-color: var(--primary-zkl-color) ;
    /* background color to -10vw */
    background-position: 0 -10vw;
}
.minusBg10 {
    margin-top: -100px;
}

.minusBg12 {
    margin-top: -120px;
}

.lh-1-2{
    line-height: 1.2;
}


.lb{
    margin-top: 30px;
    margin-left: 5px;
    padding-left: 30px;
    border-left: 4px solid #004bda;
}

/* About page   */
.health-provider-bg{
    background: url("../images/about/why-icarepro-bg1.webp");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 0;
}

.app-dev-bg{
    background: url("../images/appdev/app-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 0;
}

.who-we-serve-bg{
    background: url("../images/whoWeServe/who-we-serve.webp");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 0;
}

.contact-page-bg{
    background: url("../images/contact/contact-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    /* padding: 80px 0; */
}

.bg-contact-opacity{
    background-color: #181818;
    opacity: 0.95;
}

.text-white{
    color: #ffffff;
}
.banner-section{
    position: relative;
}
.about-col-bg-white{
    background-color: #ffffff;
    border-radius: 10px;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}
.about-col-b{
    border: 3px solid #004bda;
    border-radius: 10px;
    align-content: center;
}

.w80px {
    width: 80px;
}

.border-zk-primary2 {
    background-color: var(--primary-zk-color);
    border: none;
    color: var(--primary-zk-color);
    height: 2px;
    opacity: 1;
}
.accordion-button{
    padding: 10px 20px !important;
}
.accordion-button:focus{
    box-shadow: 0;
}
.accordion-button:not(.collapsed){
    background-color: #ededed !important;
}
.accordion-button:focus{
    box-shadow: none !important;
}
.accordion-item {
    border-left: 0 !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
}

.accordion{
    border: 0;
}

.slick-slide-image {
    max-width: 100%;
    height: auto;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  }
  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #01054e !important;
  }

  .slick-slide-content{
    color: #fff;
    position: absolute;
    left: 7vw;
    top: 30%;
    width: 45%;

}


  .slick-slide-title {
    color: #fff;
    padding: 10px;
    font-size: 3.5vw;
    font-weight: 600;
    line-height: 1.4;
  }
  .slick-slide-title-desc {
    color: #fff;
    padding: 0 10px;
    font-size: 1.2vw;
  }
  .slick-slide-btn{
    font-size: 1vw;
    padding: 0 10px;
    font-weight: 500;
  }
  
  .slick-slide-btn button {
    font-size: 1.1vw !important;
    }

.slider-wrapper {
    width: 100%;

    margin: auto;
  }

  .btn-zk-white{
    background-color: #fff;
    color: black;
    border: 0;
    border-radius: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
  }

.btn-zk-white:hover{
    background-color: rgb(238, 238, 238);
    color: black;
    border: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
}
.btn-zk-white:focus{
    background-color: rgb(238, 238, 238) !important;
    color: black !important;
}
.home-banner .row{
    margin: 0;
}
.footer-bg{
    background: url("../images/footer_bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
}

.footer-links-section{
    padding-top: 200px;
    padding-bottom: 40px;
}
.border-gray2{
    border-top: 1px solid #ffffff40
}

  .rounded30{
    border-radius: 30px;
  }

  .rounded20{
    border-radius: 20px;
  }

  .rounded10{
    border-radius: 10px;
  }

  .rounded6{
    border-radius: 6px;
  }

  .btn-zk-outline-white{
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    /* height: 3em; */
    min-width: 120px;
    font-family: work-sans;
    font-weight: 600 !important;
    border: 1px solid #fff;

}
.btn-zk-primary{
    background-color: var(--primary-zk-color);
    color: #fff;
    cursor: pointer;
}

.btn-zk-primary-banner{
    background-color: var(--primary-zk-color);
    color: #fff;
    cursor: pointer;
    /* padding: 2vw 5vw; */
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
}

.btn-zk-primary-banner:hover{
    background-color: var(--primary-zk-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
    padding: 1vw 2vw;
}

.btn-zk-white-banner{
    background-color: #fff;
    color: var(--primary-zk-color);
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
    border-radius: 30px;
}

.btn-zk-white-banner:hover{
    background-color: #fdfdfd;
    opacity: 0.94;
    color: var(--primary-zk-color);
    cursor: pointer;
    padding: 1vw 2vw;
}
.home-background-banner{
    background-image: url('../images/home/banner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 82vh;
    width: 100%;
    position: relative;
}
.home-banner-content{
    position: absolute;
    top: 15%;
    width: 55%;
    left: 13%;
}
.home-banner-heading{
    letter-spacing: 3px;
}
.home-banner-btn{
    font-size: 18px;
    border-radius: 30px !important;
}
.btn-zk-primary:hover{
    background-color: var(--primary-zk-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.shadow-zk-1{
    box-shadow: 2px 3px 3px 2px #dadada !important;
}
.testimonial-bg{
    background: url("../images/home/testimonial-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
}
.padding10xem{
    padding: 10em 0;
}

.padding7xem{
    padding: 7em 0;
}

.padding5xem{
    padding: 5em 0;
}

.padding2-2xem{
    padding: 2.2em 0;
}
.padding3xem{
    padding: 3em 0;
}
#website-nav .align-items-nav{
    align-items: center;
  }

.integrated-section-img-1{
    background: url('../images/eprescription/section_img_1.webp');
    background-position: unset;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.integrated-section-img-2{
    background: url('../images/eprescription/section_img_2.webp');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.integrated-section-img-3{
    background: url('../images/eprescription/section_img_3.webp');
    background-position: unset;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.integrated-section-img-4{
    background: url('../images/eprescription/section_img_4.webp');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.review-section-img-1{
    background: url('../images/ReputationManagement/section_img_1.webp');
    background-position: unset;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.review-section-img-2{
    background: url('../images/ReputationManagement/section_img_2.webp');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.review-section-img-3{
    background: url('../images/ReputationManagement/section_img_3.webp');
    background-position: unset;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.appointment-section-img-1{
    background: url('../images/Appointment/section_img_1.webp');
    background-position: unset;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.appointment-section-img-2{
    background: url('../images/Appointment/section_img_2.webp');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.appointment-section-img-3{
    background: url('../images/Appointment/section_img_3.webp');
    background-position: unset;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}


.navbar-nav .dropdown-menu {
    background: #fdfdfd;
    border: rgb(255, 255, 255);
    box-shadow: #dadada 0px 0px 2px 0px;
    border-radius: 0;
    border-top: 2px solid var(--primary-zk-color);
    position: static;
    width: 100%;
}

#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}
#website-nav .sub-nav-menu .dropdown-menu {
    width: max-content;
    margin-top: 0;
}

.emr_featured_col{
    /* middle */
    min-height: 55px;

}

.about-weuse-section1{
    background: url('../images/about/ground.webp');
    color: #fff;
    border-radius: 10px;
    min-height: 190px;
    height: 190px;
    width:100%;
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
    vertical-align:middle;
    display:table-cell;
}

.about-weuse-section2{
    background: url("../images/about/online.webp");
    color: #fff;
    border-radius: 10px;
    min-height: 190px;
    height: 190px;
    width:100%;
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
    vertical-align:middle;
    display:table-cell;
}

.about-weuse-section3{
    background: url("../images/about/hipaa.webp");
    color: #fff;
    border-radius: 10px;
    min-height: 190px;
    height: 190px;
    width:100%;
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
    vertical-align:middle;
    display:table-cell;

}

.about-weuse-section4{
    background: url("../images/about/designing.webp");
    color: #fff;
    border-radius: 10px;
    min-height: 190px;
    height: 190px;
    width:100%;
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
    vertical-align:middle;
    display:table-cell;
}

.home-mobile-pic{
    width: 30%;
}
.page-banner-title{
    color: #fff;
    /* padding: 10px; */
    position: absolute;
    font-family: Roboto;
    font-weight: bolder;
    /* text-transform: uppercase; */
    left: 8vw;
    font-size: 4.2vw;
    line-height: 4.3vw;
    top: 33%;
    letter-spacing: 1px;
    /* width: 80%; */
  }
  .page-banner-text1{
    font-size: 2vw;
  }
  .page-banner-desc{
    font-size: 1.4vw;
    font-weight: 400;
    margin-top: 10px;
    /* margin-top: 1px; */




  }

.navbar-nav .dropdown-menu {
    box-shadow: 0;
    border-radius: 0;
    border: 0;
    padding: 10px;
}

.digital-marketing-icon{
    width: 50% !important;
}
.about-weuse-section{
    min-height: 175px !important;
}

.about-ourphilosophy{
    width: 130px;
    height: 120px;
}
.home-healthimages{
    width: 40%;
}
.home-practice-img{
    width: 100%;
}

.home-practice-animation{
    width: 50%;
}
.whoweserve-healthimages{
    width: 25%;
} 

.about-connection-sec{
    min-height: 460px;
}
.marginTopvitals1{
    margin-top: -180px;
}

.paddingTopvitals2{
    padding-top: 190px;
}
.loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    animation: bg 1s;
  }


  .loading-wrapper
    {
        position: absolute;
        top: 42%;
        left: 50%;
        width: 142px;
        height: 40px;
        margin: -20px 0 0 -71px;
        background: white;
        filter: contrast(20);
        z-index:10001
        
    }
    .loading-wrapper video{
        width:100px;
        height:100px;
    }
    .loading-dot
    {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 12px;
        left: 5px;
        filter: blur(4px);
        background: #000;
        border-radius: 50%;
        transform: translateX(0);
        animation: dot 2.8s infinite;
    }
    .loading-dots
    {
        transform: translateX(0);
        margin-top: 12px;
        margin-left: 31px;
        animation: dots 2.8s infinite;
    }
    .loading-wrapper span
    {
        display: block;
        float: left;
        width: 16px;
        height: 16px;
        margin-left: 16px;
        filter: blur(4px);
        background: #000;
        border-radius: 50%;
    }

    .blog-card:hover{
        background-color: var(--primary-zkl-color);
    }

    @media screen and (max-width: 1281px) {
        .home-marketing-section-content .fs-zk-16{
            font-size: 15px;
    
        }
        
    }
@media screen and (max-width: 1150px){

}

@media screen and (max-width: 991px) {
    .home-mobile-pic{
        width: 30%;
    }
    .logo-img{
        height:45px;
    }
    #website-responsive .minusBg10, #website-responsive .minusBg12 {
        margin-top: 0;
    }

    #website-responsive .fs-zk-56{
        font-size:40px;
    }

    #website-responsive .fs-zk-52{
        font-size:38px;
    }


    #website-responsive .fs-zk-48{
        font-size:36px;
    }

    #website-responsive .fs-zk-40{
        font-size:32px;
    }

    #website-responsive .fs-zk-35{
        font-size:28px;
    }

    #website-responsive .padding10xem, #website-responsive .padding7xem, #website-responsive .padding5xem{
        padding: 3em 0;
    }

    #website-responsive .padding3xem{
        padding: 2em 0;
    }

    /* .btn-zk-white{
        padding: 0;
    }
    .btn-zk-white:hover{
        padding: 0;
    }
    .btn-zk-white button{
        padding: 0;
    } */
    .page-banner-title{
        top:25%;
    }
    .about-weuse-section1, .about-weuse-section2, .about-weuse-section3, .about-weuse-section4{
        width: 100%;
        height: unset;
        min-height: unset;
        display: block;
        padding: 40px 30px !important;
        font-weight: 600;
    }
    .navbar{
        padding: unset;
    }
    .home-banner-content-mobile{
        background-image: url('../images/home/banner_mb.webp');
        background-size: cover;
        /* background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 55vh;
        width: 100%;
        position: relative; */
    }
    /* .home-banner-content{
        position: absolute;
        top: 15%;
        width: 50%;
        left: 3%;
    } */
}

@media screen and (max-width: 768px) {
    .integrated-section-img-1, .integrated-section-img-2, .integrated-section-img-3, .integrated-section-img-4{
        background-size: cover;
        background-attachment: unset;
    }
    .review-section-img-1, .review-section-img-2, .review-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }
    .appointment-section-img-1, .appointment-section-img-2, .appointment-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }

    #website-responsive .minusBg10 {
        margin-top: 0;
    }
    .footer-logo{
        height: 50px;
    }
    .home-practice-img{
        width: 40%;
    }
    .home-practice-animation{
        width: 70%;
    }

    #website-responsive .fs-zk-20{
        font-size:16px;
    }
    .slick-slide-content{
        top: 35%;
        width: 64%;
        left: 2vw;
    }
    .slick-slide-title-desc{
        font-size: 2.7vw;
        font-weight: 500;
        padding: 0 10px 3px 10px;
    }

    .slick-slide-title{
        font-size: 5.4vw;
        padding: 0 10px;
        line-height: 25px;

    }
    .slick-slide-btn{
        margin-top: 10px;
    }

    .home-banner-btn{
        font-size: 12px !important;
    }
    .page-banner-btn{
        margin-top: 10px;
    }
    .btn-zk-white-banner{
        font-size: 13px;
        /* margin-top: 20px; */
    }
    .btn-zk-primary-banner{
        font-size: 14px;
        margin-top: 20px;
    }
    
    .digital-marketing-icon{
        width: 60% !important;
    }
    .about-weuse-section{
        min-height: 100% !important;
    }
    .home-healthimages{
        width: 30%;
    }

    .home-healthimages img{
        width: 30%;
    }
    .page-banner-title{
        font-size: 5vw;
        top: 28%;
        line-height: 5.5vw;
    }
    .page-banner-text1{
        font-size: 3vw;
    }
    .page-banner-desc{
        font-size: 3vw;
        font-weight: 500;
        display: none;
        line-height: 3vw;
    }
    .about-connection-sec{
        min-height: 100%;
    }
    .text-home-onestop{
        font-size: 24px !important; 
    }
    .about-ourphilosophy{
        width: 100px;
        height: 80px;
    }
    #reviews-lb .lb{
        margin-top: 7px;
        margin-left: 0;
        padding-left: 0;
        border-left: unset;
    }

    .marginTopvitals1{
        margin-top: -110px;
    }
    
    .paddingTopvitals2{
        padding-top: 120px;
    }

    .emr_featured_col{
        /* middle */
        min-height: 0px;
    
    }
    .footer-links-section{
        padding-bottom: 0;
    }
}
  

@media screen and (max-width: 576px) {
    .home-mobile-pic{
        width: 50%;
    }
    #website-responsive .fs-zk-56{
        font-size:28px;
    }
    #website-responsive .fs-zk-52{
        font-size:27px;
    }
    
    #website-responsive .fs-zk-48{
        font-size: 26px;
    }

    #website-responsive .fs-zk-40{
        font-size: 24px;
    }

    #website-responsive .fs-zk-35{
        font-size: 22px;
    }

    #website-responsive .fs-zk-30{
        font-size: 20px;
    }

    #website-responsive .fs-zk-22{
        font-size: 18px;
    }
    #website-responsive .fs-zk-18{
        font-size:16px;
    }
    .about-health-section .fs-zk-30{
        font-size: 23px !important;
    }

    .container-zk, .container-zk2{
        max-width: 96%;
        padding-right: 2% !important;
        padding-left: 2% !important;
        margin-right: auto;
        margin-left: auto;
    }
    
}



@media screen and (max-width: 480px) {
    .page-banner-title{
        top:23%;
    }
}

@media screen and (max-width: 440px) {
    .page-banner-title{
        top:32%;
    }
}

@media screen and (max-width: 400px) {
    .page-banner-title{
        top:35%;
    }
}

@media screen and (max-width: 320px) {
    .page-banner-title{
        top:35%;
    }
    
}

@media screen and (max-width: 280px) {
    
}

